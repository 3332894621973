<template>
    <div class="dashboard-container">
        <div class="dash-top-container">
            <div class="dash-tiles-container">
                <div class="dropdown-container">
                    <label for="team-dropdown">Team</label>
                    <select :title="selectedTeam" id="team-dropdown" v-model="selectedTeam"
                        @change="handleTeamSelection" class="dropdown" aria-label="Teams">
                        <option :value="''">Please select...</option>
                        <option v-for="team in teams" :key="team" :value="team">
                            {{ team }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="dash-tiles-container">
                <div class="dropdown-container">
                    <label for="iteration-dropdown">Sprint</label>
                    <select :title="selectedIteration.name" :disabled="!selectedTeam" id="iteration-dropdown"
                        v-model="selectedIteration" @change="handleIterationSelection" class="dropdown"
                        aria-label="Iterations">
                        <option :value="{ id: '', name: '' }">Please select...</option>
                        <option v-for="iteration in iterations" :key="iteration.id" :value="iteration">
                            {{ iteration.name + (iteration.current ? ' - Current' : '') }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="data-tiles-container">
            <div class="data-tile">
                <label for="totalWorkCount">Total Items</label>
                <h1 id="totalWorkCount">{{ workData.total }}</h1>
            </div>
            <div class="data-tile">
                <label for="inProgress">Items In progress</label>
                <h1 id="inProgress">{{ workData.totalToDo }}</h1>
            </div>
            <div class="data-tile">
                <label for="remainingWork">Remaining Work</label>
                <h1 id="remainingWork">{{ workData.remainingWork }}h</h1>
            </div>
        </div>
        <div class="main-charts-container">
            <div class="chart-column">
                <BarChart title="Sprint Status" :data="workData.state" :theme="theme" :multipleBars="false" />
            </div>
            <div class="chart-column">
                <DoughnutChart title="Work Item Type" :data="workData.type" :theme="theme" />
            </div>
            <div class="chart-column">
                <BarChart title="Work Assignment" :labels="workData.usersAssigned.labels"
                    :data="workData.usersAssigned.data" :theme="theme" :multipleBars="true" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { user } from '../Data/Store/user';
import BarChart from '../Components/Charts/BarChart.vue';
import DoughnutChart from '../Components/Charts/DoughnutChart.vue';
import {
    fetchIterations, fetchTeams, fetchDashboardWorkItemData,
    saveTeam, saveIteration, fetchDashboardSettings
} from '@/Data/Services/DevopsService';
import { statusCode } from '@/Utils/StatusCodes';
import { toast } from '@/Components/Dialogs/ToastMessage';
import { messages } from '@/Utils/Messages';
import { dashboardStore, getDefaultIteration } from '@/Data/Store/dashboard';
import { Iteration, WorkData } from '@/Interfaces/Index';

export default defineComponent({
    props: {
        theme: String
    },
    data () {
        return {
            user,
            teams: [] as string[],
            iterations: [] as Iteration[],
            selectedTeam: '' as string,
            selectedIteration: dashboardStore.iteration,
            workData: {
                type: {},
                state: {},
                usersAssigned: {
                    labels: [],
                    data: []
                },
                total: 0,
                totalToDo: 0,
                remainingWork: 0
            } as WorkData,
            dashboard: dashboardStore
        };
    },
    async mounted () {
        await this.initDashboard();
    },
    components: {
        BarChart,
        DoughnutChart,
    },
    methods: {
        async initDashboard () {
            if (this.isDashboardInitialized()) {
                this.loadDashboardFromStore();
            } else {
                await this.loadDefaultDashboardData();
            }

            await this.getWorkItemData();
        },
        isDashboardInitialized () {
            return this.dashboard.team &&
                this.dashboard.iteration &&
                this.dashboard.iteration.id &&
                this.dashboard.iteration.name &&
                this.dashboard.teamsList.length > 0 &&
                this.dashboard.iterationsList.length > 0;
        },
        loadDashboardFromStore () {
            this.selectedTeam = this.dashboard.team;
            this.selectedIteration = this.dashboard.iteration;
            this.teams = this.dashboard.teamsList;
            this.iterations = this.dashboard.iterationsList;
        },
        async loadDefaultDashboardData () {
            await this.getTeamsData();

            const dashboardSettings = await fetchDashboardSettings();
            if (this.teams.length > 0 && dashboardSettings?.team) {
                this.selectedTeam = dashboardSettings.team;
                this.dashboard.team = dashboardSettings.team;
                await this.getIterationsData();

                if (dashboardSettings?.iteration?.id && dashboardSettings?.iteration?.name) {
                    const iteration = this.iterations.find(iteration => iteration.id === dashboardSettings.iteration.id) || getDefaultIteration();
                    this.selectedIteration = iteration;
                    this.dashboard.iteration = iteration;
                }
            }
        },
        async getTeamsData () {
            const teams = await fetchTeams();
            if (!teams || teams.length <= 0) {
                toast(messages.error.getTeams, 'error');
                return;
            }
            this.teams = teams;
            this.dashboard.teamsList = teams;
        },
        async getIterationsData () {
            if (!this.selectedTeam) {
                return;
            }

            const iterations = await fetchIterations(this.selectedTeam);
            if (!iterations || iterations.length <= 0) {
                return;
            }

            const iterationsList = iterations.reverse();
            this.iterations = iterationsList;
            this.dashboard.iterationsList = iterationsList;
        },
        async getWorkItemData () {
            if (!this.selectedTeam || !this.selectedIteration || !this.selectedIteration.id || !this.selectedIteration.name) {
                return;
            }

            const workItemsdata = await fetchDashboardWorkItemData({ team: this.selectedTeam, iteration: this.selectedIteration });
            if (!workItemsdata || Object.keys(workItemsdata).length <= 0) {
                return;
            }

            this.workData = workItemsdata;
        },
        async handleTeamSelection () {
            if (!this.selectedTeam) {
                return;
            }

            this.selectedIteration = getDefaultIteration();
            this.dashboard.iteration = getDefaultIteration();
            this.iterations = [];

            const saveResponse = await saveTeam(this.selectedTeam);
            if (saveResponse !== statusCode.OK) {
                toast(messages.error.saveTeam, 'error');
                return;
            }

            await this.getIterationsData();
            this.dashboard.team = this.selectedTeam;
        },
        async handleIterationSelection () {
            if (!this.selectedIteration) {
                return;
            }

            const saveResponse = await saveIteration(this.selectedIteration);
            if (saveResponse !== statusCode.OK) {
                toast(messages.error.saveIteration, 'error');
                return;
            }

            await this.getWorkItemData();
            this.dashboard.iteration = this.selectedIteration;
        }
    }
});
</script>

<style lang="scss" scoped>
.dashboard-container {
    padding: 5em 1em 1em 1em;
    width: 100%;
    height: auto;
}

.dash-top-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
}

.dash-tiles-container {
    width: 15rem;
}

.data-tile {
    width: auto;
    min-width: 7rem;
    text-align: center;
}

.dash-tiles-container,
.data-tile {
    border-radius: 5px;
    padding: 0.5rem;
    overflow: hidden;
}

.dropdown-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.data-tiles-container {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    width: 100%;
}

.main-charts-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}

.chart-column {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 1rem;
    overflow: hidden;
    max-width: calc(50% - 0.5rem);
    width: calc(50% - 0.5rem);
    height: 600px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.dark .chart-column,
.dark .dash-tiles-container,
.dark .data-tile {
    transition: background 0.5s ease-in-out;
    background: $secondary-dark;
    box-shadow: 0 2px 8px $primary-purple;
}

.light .chart-column,
.light .dash-tiles-container,
.light .data-tile {
    transition: background 0.5s ease-in-out;
    background: $third-light;
    box-shadow: 0 2px 8px $light-box-shadow;
}

.dropdown {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
    transition: all 0.3s ease;
    border: 1px solid rgba(95, 105, 225, 0.8);

    &:focus {
        outline: none;
        box-shadow: 0 0 4px rgba(95, 105, 225, 0.8);
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.dark .dropdown {
    background-color: $primary-dark;
    color: #ffffff;
}

.light .dropdown {
    color: #000000;
    background-color: $primary-light;
}

@media (max-width: 990px) {
    .main-charts-container {
        flex-direction: column;
    }

    .chart-column {
        overflow: hidden;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}
</style>
