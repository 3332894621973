import { DashboardState, Iteration } from '@/Interfaces/Dashboard';
import { reactive } from 'vue';

// Create a new store instance.
const initialState: DashboardState = {
    team: '',
    teamsList: [],
    iteration: { id: '', name: '', current: false } as Iteration,
    iterationsList: [],
};

export const getDefaultIteration = (): Iteration => {
    return initialState.iteration;
};

export const dashboardStore: any = reactive({ ...initialState });

export const resetDashboardStore = () => {
    Object.assign(dashboardStore, initialState);
};