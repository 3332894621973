import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "dashboard-container" }
const _hoisted_2 = { class: "dash-top-container" }
const _hoisted_3 = { class: "dash-tiles-container" }
const _hoisted_4 = { class: "dropdown-container" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "dash-tiles-container" }
const _hoisted_8 = { class: "dropdown-container" }
const _hoisted_9 = ["title", "disabled"]
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "data-tiles-container" }
const _hoisted_12 = { class: "data-tile" }
const _hoisted_13 = { id: "totalWorkCount" }
const _hoisted_14 = { class: "data-tile" }
const _hoisted_15 = { id: "inProgress" }
const _hoisted_16 = { class: "data-tile" }
const _hoisted_17 = { id: "remainingWork" }
const _hoisted_18 = { class: "main-charts-container" }
const _hoisted_19 = { class: "chart-column" }
const _hoisted_20 = { class: "chart-column" }
const _hoisted_21 = { class: "chart-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarChart = _resolveComponent("BarChart")!
  const _component_DoughnutChart = _resolveComponent("DoughnutChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "team-dropdown" }, "Team", -1)),
          _withDirectives(_createElementVNode("select", {
            title: _ctx.selectedTeam,
            id: "team-dropdown",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTeam) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTeamSelection && _ctx.handleTeamSelection(...args))),
            class: "dropdown",
            "aria-label": "Teams"
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("option", { value: '' }, "Please select...", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (team) => {
              return (_openBlock(), _createElementBlock("option", {
                key: team,
                value: team
              }, _toDisplayString(team), 9, _hoisted_6))
            }), 128))
          ], 40, _hoisted_5), [
            [_vModelSelect, _ctx.selectedTeam]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[7] || (_cache[7] = _createElementVNode("label", { for: "iteration-dropdown" }, "Sprint", -1)),
          _withDirectives(_createElementVNode("select", {
            title: _ctx.selectedIteration.name,
            disabled: !_ctx.selectedTeam,
            id: "iteration-dropdown",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedIteration) = $event)),
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleIterationSelection && _ctx.handleIterationSelection(...args))),
            class: "dropdown",
            "aria-label": "Iterations"
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("option", { value: { id: '', name: '' } }, "Please select...", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.iterations, (iteration) => {
              return (_openBlock(), _createElementBlock("option", {
                key: iteration.id,
                value: iteration
              }, _toDisplayString(iteration.name + (iteration.current ? ' - Current' : '')), 9, _hoisted_10))
            }), 128))
          ], 40, _hoisted_9), [
            [_vModelSelect, _ctx.selectedIteration]
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _cache[8] || (_cache[8] = _createElementVNode("label", { for: "totalWorkCount" }, "Total Items", -1)),
        _createElementVNode("h1", _hoisted_13, _toDisplayString(_ctx.workData.total), 1)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[9] || (_cache[9] = _createElementVNode("label", { for: "inProgress" }, "Items In progress", -1)),
        _createElementVNode("h1", _hoisted_15, _toDisplayString(_ctx.workData.totalToDo), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[10] || (_cache[10] = _createElementVNode("label", { for: "remainingWork" }, "Remaining Work", -1)),
        _createElementVNode("h1", _hoisted_17, _toDisplayString(_ctx.workData.remainingWork) + "h", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_BarChart, {
          title: "Sprint Status",
          data: _ctx.workData.state,
          theme: _ctx.theme,
          multipleBars: false
        }, null, 8, ["data", "theme"])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_DoughnutChart, {
          title: "Work Item Type",
          data: _ctx.workData.type,
          theme: _ctx.theme
        }, null, 8, ["data", "theme"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createVNode(_component_BarChart, {
          title: "Work Assignment",
          labels: _ctx.workData.usersAssigned.labels,
          data: _ctx.workData.usersAssigned.data,
          theme: _ctx.theme,
          multipleBars: true
        }, null, 8, ["labels", "data", "theme"])
      ])
    ])
  ]))
}