<template>
    <div v-if="editor">
        <editor-content :editor="editor" />

        <div class="toolbar">
            <div v-tooltip.top="'Bold'">
                <ToolbarButton :iconSrc="icons.textEditor.bold" @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run() || !editable"
                    :class="{ 'is-active': editor.isActive('bold') }" />
            </div>

            <div v-tooltip.top="'Italic'">
                <ToolbarButton :iconSrc="icons.textEditor.italic" @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="!editor.can().chain().focus().toggleItalic().run() || !editable"
                    :class="{ 'is-active': editor.isActive('italic') }" />
            </div>

            <div v-tooltip.top="'Strike'">
                <ToolbarButton :iconSrc="icons.textEditor.strike" @click="editor.chain().focus().toggleStrike().run()"
                    :disabled="!editor.can().chain().focus().toggleStrike().run() || !editable"
                    :class="{ 'is-active': editor.isActive('strike') }" />
            </div>

            <div v-tooltip.top="'Code block'">
                <ToolbarButton :iconSrc="icons.textEditor.codeBlock" @click="editor.chain().focus().toggleCode().run()"
                    :disabled="!editor.can().chain().focus().toggleCode().run() || !editable"
                    :class="{ 'is-active': editor.isActive('code') }" />
            </div>

            <div v-tooltip.top="'Bullet list'">
                <ToolbarButton :iconSrc="icons.textEditor.bulletList"
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :class="{ 'is-active': editor.isActive('bulletList') }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Ordered list'">
                <ToolbarButton :iconSrc="icons.textEditor.orderedList"
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="{ 'is-active': editor.isActive('orderedList') }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Heading 1'">
                <ToolbarButton text="H1" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Heading 2'">
                <ToolbarButton text="H2" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Heading 3'">
                <ToolbarButton text="H3" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Heading 4'">
                <ToolbarButton text="H4" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Heading 5'">
                <ToolbarButton text="H5" @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                    :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }" :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Upload image'">
                <input type="file" ref="fileInput" style="display:none" @change="uploadImageFromLocal">
                <ToolbarButton :iconSrc="icons.textEditor.fileUpload" @click="($refs as any).fileInput.click()"
                    :disabled="!editable" />
            </div>

            <div v-tooltip.top="'Embed url image'">
                <ToolbarButton :iconSrc="icons.textEditor.urlUpload" @click="addImage" :disabled="!editable" />
            </div>
        </div>
    </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Image as TipTapImage } from "@tiptap/extension-image";
import Placeholder from "@tiptap/extension-placeholder";
import ToolbarButton from '@/Components/Buttons/ToolbarButton.vue';
import { toast } from '../Dialogs/ToastMessage';
import { messages } from '@/Utils/Messages';
import { icons } from '@/Utils/Assets';
import { refinement } from '@/Data/Store/refinement';
import { user } from '@/Data/Store/user';
import { resizeImage } from '@/Utils/HelperFunctions';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ''
        },
        fieldName: {
            type: String,
            default: ''
        }
    },
    emits: ['update:modelValue', 'dataChange'],
    components: {
        EditorContent,
        ToolbarButton
    },
    methods: {
        addImage () {
            if (this.editable) {
                const url = window.prompt('URL');
                if (url) {
                    this.editor.chain().focus().setImage({ src: url }).run();
                }
            }
        },
        addImageFromClipboard () {
            if (this.editable) {
                navigator.clipboard.read().then(data => {
                    data.forEach(async (item) => {
                        if (!item.types.includes('image/png') && !item.types.includes('image/jpeg')) {
                            toast(messages.error.fileType, 'error');
                            return;
                        }
                        const blob = await item.getType('image/png') || item.getType('image/jpeg');
                        const reader = new FileReader();
                        reader.onload = () => {
                            const base64data = reader.result;
                            this.editor.chain().focus().setImage({ src: base64data }).run();
                        };
                        reader.readAsDataURL(blob);
                    });
                }).catch(err => {
                    console.error('Failed to read clipboard data', err);
                });
            }
        },

        // Method to upload image from local system
        async uploadImageFromLocal (event: any) {
            if (this.editable) {
                try {
                    const file = event.target.files[0];
                    if (!file.type.includes('image/png') && !file.type.includes('image/jpeg')) {
                        toast(messages.error.fileType, 'error');
                        return;
                    }
                    const reader = new FileReader();
                    reader.onload = async () => {
                        const resizedBase64 = await resizeImage(reader.result);
                        this.editor.chain().focus().setImage({ src: resizedBase64 }).run();
                    };

                    reader.readAsDataURL(file);
                } catch (error) {
                    console.log(error);
                    toast(messages.error.uploadImage, 'error');
                }
            }
        },
    },
    data () {
        return {
            editor: null as any,
            icons,
            editable: (refinement.session.data.restricted && refinement.session.users[user.userData?.id].owner) || !refinement.session.data.restricted
        };
    },
    watch: {
        modelValue (value: any) {
            // HTML
            const isSame = this.editor.getHTML() === value;

            // JSON
            // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

            if (isSame) {
                return;
            }
            this.editor.commands.setContent(value, false);
        },
    },
    mounted () {
        this.editor = new Editor({
            editable: this.editable,
            editorProps: {
                handleDOMEvents: {
                    paste: (view: any, event: any) => {
                        if (this.editable) {
                            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
                            for (const item of items) {
                                if (item.type.includes('image')) {
                                    const blob = item.getAsFile();
                                    const reader = new FileReader();

                                    reader.onload = async () => {
                                        const resizedBase64 = await resizeImage(reader.result) as any;
                                        this.editor.chain().focus().setImage({ src: resizedBase64 }).run();
                                        const newValue = this.editor.getHTML(); // Get updated HTML content
                                        this.$emit('update:modelValue', newValue); // Update model value
                                    };

                                    reader.readAsDataURL(blob);
                                    event.preventDefault(); // Prevent default paste behavior
                                    break; // Only handle the first image found
                                }
                            }
                        }
                    }
                }
            },
            extensions: [
                StarterKit,
                TipTapImage.configure({
                    allowBase64: true,
                }),
                Placeholder.configure({
                    placeholder: this.placeholder
                }),
                Link
            ],
            content: this.modelValue,
            onUpdate: () => {
                // HTML
                this.$emit('update:modelValue', this.editor.getHTML());

                // JSON
                // this.$emit('update:modelValue', this.editor.getJSON())

                this.$emit('dataChange', this.fieldName);
            }
        });
    },

    beforeUnmount () {
        this.editor.destroy();
    },
});
</script>

<style lang="scss">
.light .tiptap {
    transition: background 0.5s ease-in-out;
    color: $black;
    background: $third-dark;
}

.dark .tiptap {
    transition: background 0.5s ease-in-out;
    color: $white;
    background: $secondary-dark;
}

.tiptap {
    padding: 0.3em;
}

.ProseMirror {
    display: flex;
    flex-direction: column;
    margin-bottom: 3px;

    li p {
        margin: 0;
    }

    img {
        max-width: 95%;
        height: auto;
        align-self: flex-start;
        margin-bottom: 1em;
    }

    link,
    a {
        cursor: pointer;
    }

    &:focus {
        outline: 1px solid $primary-purple !important;
    }

    &:focus img.ProseMirror-selectednode {
        outline: 3px solid $secondary-purple;
    }

    // code {
    //     background-color: rgba(#616161, 0.1);
    //     color: #616161;
    // }
}

/* Color swatches */
.color {
    white-space: nowrap;

    &::before {
        background-color: var(--color);
        border: 1px solid rgba(128, 128, 128, 0.3);
        border-radius: 2px;
        content: " ";
        display: inline-block;
        height: 1em;
        margin-bottom: 0.15em;
        margin-right: 0.1em;
        vertical-align: middle;
        width: 1em;
    }
}

.items {
    padding: 0.2rem;
    position: relative;
    border-radius: 0.5rem;
    background: #FFF;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.05),
        0px 10px 20px rgba(0, 0, 0, 0.1),
    ;
}

.item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;

    &.is-selected {
        border-color: #000;
    }
}

.mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
}

.toolbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px;
}

.tiptap p.is-editor-empty:first-child::before,
.tiptap p.is-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}
</style>