<template>
  <div class="refinement-card">
    <div class="refinement-card-header">
      <h2 :title="refinementSession?.title" class="refinement-card-title">{{ refinementSession?.title }}</h2>
      <img class="closeBtn" :src="icons.refinement.delete" width="30" height="30" @click="handleDeleteSession"
        loading="lazy" />
    </div>
    <div class="refinement-card-body">
      <span class="refinement-card-subtitle">{{ refinementSession?.type }}</span>
      <p class="query-name" :title="refinementSession?.queryName">{{ refinementSession?.queryName }}</p>
    </div>
    <div class="refinement-card-footer">
      <img :src="refinementSession.restricted ? icons.refinement.restricted : icons.refinement.unrestricted" width="30"
        height="30" />
      <GenericButton text="Join session" @click="handlestartRefinement" />
    </div>
  </div>
</template>

<script lang="tsx">
import { defineComponent } from 'vue';
import GenericButton from '../Buttons/GenericButton.vue';
import { icons } from '@/Utils/Assets';

export default defineComponent({
  props: {
    refinementSession: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    GenericButton
  },
  methods: {
    handleDeleteSession () {
      this.$emit('deleteSession');
    },
    handlestartRefinement () {
      this.$emit('startRefinement');
    }
  },
  data () {
    return {
      icons
    };
  }
});
</script>

<style lang="scss" scoped>
.closeBtn {
  cursor: pointer;

  &:hover {
    background-color: $light-box-shadow;
    border-radius: 5px;
  }
}

.refinement-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 500px;
}

.refinement-card-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.refinement-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  flex: 1;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.refinement-card-title {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.refinement-card-subtitle {
  font-size: 16px;
  color: $info-text;
}

.refinement-card-body {
  padding: 0 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.query-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.refinement-card-footer {
  padding: 5px 16px 16px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto; // Ensures footer sticks to the bottom
}
</style>
